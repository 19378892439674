<template>
  <div class="FileUpload">
    <Header />

    <!-- Content -->
    <div class="PageContentWrap">
      <div class="InnerWrap">
        <!-- Intro -->
        <div class="Intro">
          <p>{{ mixWB('UPLOAD_PAGE_INTRO_1') }}</p>
          <p>{{ mixWB('UPLOAD_PAGE_INTRO_2') }}</p>
        </div>

        <!-- Top -->
        <div class="Top">
          <div class="Left">
            <span>{{ mixWB('FILES') }}</span>
          </div>
          <div class="Right">
            <label
              ref="UploadButton"
              for="newProjectFiles"
              class="UploadButton">
              <input
                id="newProjectFiles"
                name="newProjectFiles"
                type="file"
                :accept="allowedFileTypes"
                multiple
                @change="onFilesSelected" />
              <div class="TopPadding"/>
              <div>
                <Button
                  :text="mixWB('UPLOAD_FILES')"
                  :size="'small'"
                  :fullWidth="false"
                  @button-click="$refs.UploadButton.click()" />
              </div>
            </label>
          </div>
        </div>

        <!-- Body -->
        <div class="FileList">
          <!-- No files -->
          <span
            v-if="!files.length"
            class="NoFilesText">
            {{ mixWB('NO_FILES_TO_SHOW') }}
          </span>

          <!-- With files -->
          <FileRow
            v-for="fileObject in files"
            :key="fileObject.timestamp + fileObject.name"
            :fileObject="fileObject" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from '@/components/ResultItems/Header.vue'
import Button from '@/components/Buttons/Button.vue'
import { mapGetters } from 'vuex'
import { sortBy } from 'lodash-es'
import FileRow from '@/components/ResultItems/FileRow.vue'
import EventBus from '@/EventBus'

export default {
  name: 'FileUpload',
  data() {
    return {
      maxFiles: 10,
      pendingFiles: [],
      allowedFileTypes: [
        'application/pdf',
        'image/png',
        'image/jpeg',
        'image/gif',
        'image/webp',
        'image/avif',
      ],
    }
  },
  computed: {
    ...mapGetters([
      'imageUploadFolder',
      'currentScreeningData',
    ]),
    files() {
      return sortBy([...this.currentScreeningData.files, ...this.pendingFiles], ['timestamp'])
    },
    basePath() {
      return `${ this.imageUploadFolder }Files/`
    },
  },
  methods: {
    onFilesSelected(event) {
      const { files } = event.target

      if (!files || !files[0]) {
        return
      }

      if (files.length > this.maxFiles) {
        window.alert(this.mixWB('YOU_CAN_ONLY_UPLOAD_X_FILES_AT_A_TIME', [this.maxFiles]))
        return
      }

      files.forEach((file) => this.onNewFile(file))
    },
    onNewFile(file) {
      // Check if file type is allowed
      if (!this.allowedFileTypes.includes(file.type)) {
        return
      }

      // Check if an uploaded file with same name exists
      const existingFileObject = this.currentScreeningData.files.find((x) => x.name === file.name)

      if (existingFileObject) {
        const answer = window.confirm(this.mixWB('FILE_OVERWRITE_WARNING'))
        if (!answer) {
          return
        }

        this.$store.dispatch('removeFileFromScreening', existingFileObject.path)
      }

      // Check for pending file with same name
      const existingPendingFileObject = this.pendingFiles.find((x) => x.name === file.name)
      if (existingPendingFileObject) {
        return
      }

      // Create file object
      const timestamp = Date.now()

      const fileObject = {
        path: `${ this.basePath }${ file.name }`,
        name: file.name,
        type: file.type,
        size: file.size,
        timestamp,
        status: 'pending',
      }

      // Add to pending files
      this.pendingFiles.push(fileObject)

      // Upload file
      this.$store.dispatch('uploadFile', {
        fileObject,
        file,
      })
    },
    onFileUploaded({ path }) {
      const fileObject = this.pendingFiles.find((x) => x.path === path)

      if (!fileObject) {
        return
      }

      // Update status
      fileObject.status = 'uploaded'

      // Remove from pending
      const index = this.pendingFiles.findIndex((x) => x.path === path)
      this.pendingFiles.splice(index, 1)

      // Add to screening
      this.$store.dispatch('addFileToScreening', fileObject)
    },
    onFileUploadError({ path }) {
      const fileObject = this.pendingFiles.find((x) => x.path === path)

      if (!fileObject) {
        return
      }

      fileObject.status = 'upload-error'
    },
    onFileDeleted({ path }) {
      const fileObject = this.currentScreeningData.files.find((x) => x.path === path)

      if (!fileObject) {
        return
      }

      this.$store.dispatch('removeFileFromScreening', path)
    },
  },
  components: {
    Header,
    Button,
    FileRow,
  },
  created() {
    EventBus.$on('file-uploaded', this.onFileUploaded)
    EventBus.$on('file-upload-error', this.onFileUploadError)
    EventBus.$on('file-deleted', this.onFileDeleted)
  },
  destroyed() {
    EventBus.$off('file-uploaded', this.onFileUploaded)
    EventBus.$off('file-upload-error', this.onFileUploadError)
    EventBus.$off('file-deleted', this.onFileDeleted)
  },
}
</script>

<style lang="stylus" scoped>
  .FileUpload
    display block

  .PageContentWrap
    page-wrap-outer-1()
    padding-top 0

  .InnerWrap
    page-wrap-inner-2()

  .Intro
    margin-bottom 10px

  .Top
    display flex
    align-items flex-end
    justify-content space-between
    border-bottom 1px solid $color_grey_lighter
    padding-bottom 10px
    margin-bottom 10px
    .Left
      span
        margin-bottom -5px
    .UploadButton
      input
        box(0px)
        overflow hidden
      >>> .ButtonWrap
        margin-bottom 0

  .FileList
    .NoFilesText
      font-style italic
</style>
