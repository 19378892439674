<template>
  <div
    class="FileRow"
    :class="RootClasses">
    <div class="Left">
      <div class="Icon">
        <FilePDF v-if="fileObject.type === 'application/pdf'" />
        <FileImg v-else />
      </div>
      <div class="TitleWrap">
        <template v-if="href">
          <a
            :href="href"
            target="_blank"
            class="Title">{{ fileObject.name }}</a>
        </template>

        <template v-else>
          <span class="Title">{{ fileObject.name }}</span>
        </template>
        <span
          class="Date">
          {{ mixGetDate(fileObject.timestamp, mixWB('DATE_FORMAT_2')) }}
        </span>
      </div>
    </div>
    <div class="Right">
      <span
        class="Date">
        {{ mixGetDate(fileObject.timestamp, mixWB('DATE_FORMAT_2')) }}
      </span>
      <div
        v-if="allowDelete || (!allowDelete && status !== 'uploaded')"
        class="Options">
        <span
          v-if="status === 'uploaded'"
          class="DeleteOption"
          @click="onDeleteClick">{{ mixWB('DELETE').toLowerCase() }}</span>

        <span v-else-if="status === 'upload-error'">
          {{ mixWB('THE_FILE_COULD_NOT_BE_UPLOADED') }}
        </span>

        <div
          v-else
          class="Loading">
          <Circular size="tini-tiny" />
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import Circular from '@/components/Progress/Circular.vue'
import FilePDF from '@/assets/svg/file-pdf.svg?inline'
import FileImg from '@/assets/svg/file-img.svg?inline'

export default {
  name: 'FileRow',
  props: {
    fileObject: {
      type: Object,
      required: true,
    },
    allowDelete: {
      type: Boolean,
      default: true,
      required: false,
    },
  },
  data() {
    return {
      isDeleting: false,
      href: '',
    }
  },
  computed: {
    RootClasses() {
      return {
        IsPending: this.status === 'pending',
        IsUploaded: this.status === 'uploaded',
        IsUploadError: this.status === 'upload-error',
        IsDeleting: this.status === 'deleting',
      }
    },
    status() {
      if (this.isDeleting) {
        return 'deleting'
      }
      return this.fileObject.status
    },
  },
  methods: {
    onDeleteClick() {
      const answer = window.confirm(this.mixWB('ARE_YOU_SURE'))
      if (!answer) {
        return
      }

      this.isDeleting = true
      this.$store.dispatch('deleteFile', this.fileObject.path)
    },
  },
  components: {
    FilePDF,
    FileImg,
    Circular,
  },
  created() {
    if (this.status === 'uploaded') {
      this.mixGetDownloadURL({ path: this.fileObject.path })
        .then((url) => {
          this.href = url
        })
    }
  },

}
</script>

<style lang="stylus" scoped>
  .FileRow
    display flex
    align-items center
    justify-content space-between
    margin-bottom 10px
    .Left
      display flex
      align-items center
      flex-grow 2
      min-width 0
      .Icon
        box(26px)
        flex-shrink 0
        svg
          fill #CC4B4C
      .TitleWrap
        margin-left 10px
        min-width 0
        flex-grow 2
        .Title
          truncated()
        .Date
          font-size 0.75rem
          truncated()
          display none
    .Right
      display flex
      align-items center
      margin-left 20px
      .Date
        width 130px
        display none
      .Options
        display flex
        align-items center
        justify-content flex-end
        margin-left 100px
      .Loading
        position relative
        box(26px)
      .DeleteOption
        color $color_error
        cursor pointer
        &:hover
          text-decoration underline

    &.IsPending
      span
        color $color_grey_light
    &.IsUploaded
      .Title
        cursor pointer
        &:hover
          text-decoration underline
      .Date
        color $color_grey
        display block
    &.IsUploadError
      span
        color $color_error
    &.IsDeleting
      opacity 0.5
      pointer-events none

    +below($tablet)
      .Left
        .TitleWrap
          .Date
            display block
      .Right
        .Date
          display none
        .Options
          width auto
          min-width 100px
</style>
